
import { defineComponent, reactive, ref, toRefs } from "vue";
import base from "@/api/base.js";

export default defineComponent({
  name: "check-results",
  data() {
    return {
      qscCheckList: {
        store: {},
        check_ver: {
          brand: { cmp: {} },
        },
        check_item: [{ middle_ctgry: {}, item_detail: [], result_detail: [] }],
        inspector_sgntr: "",
        inspected_sgntr: "",
      },
      qscCheck: {},
      fileList: [],
      newImage: [],
      scoreId: "",
      result: [],
      fileroute: "",
      large_ctgry: "",
      sessionId: "",
      fileItem: {},
    };
  },

  // props: { id: String },

  mounted() {
    this.sessionId = sessionStorage.getItem("id");
    this.getData();

    base
      .getCategoryList({ ctgry_ty: "QSCCT10010" })
      .then((res) => {
        console.log(res);
        this.largectgry = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getData() {
      const param = { large_ctgry_pk: this.large_ctgry };
      base
        .getResultCheckList(this.sessionId, param)
        .then((res) => {
          console.log(res);
          this.qscCheckList = res.data;
          this.getSelectedId(this.qscCheckList.check_item);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedId(check_item) {
      console.log("체크아이템", check_item);
      for (let i = 0; i < check_item.length; i++) {
        const item_detail = check_item[i].item_detail;
        const id = item_detail
          .filter((detail) => detail.result == true)
          .map((detail) => detail.id);
        const id2 = parseInt(id.toString());
        this.qscCheckList.check_item[i].result_value = id2;
        console.log("결과반영", this.qscCheckList.check_item[i].result_value);
      }
    },

    getSelected(e, detail, obj) {
      this.scoreId = e.target.value;
      const score = detail
        .filter((list) => list.id == this.scoreId)
        .map((list) => list.item_score);
      console.log(score);
      obj.check_rslt_score = score;
    },

    addFile(fileTy) {
      console.log(fileTy);
      document.getElementById(fileTy).click();
    },
    getFilterData() {
      this.getData();
    },

    viewImage(item) {
      this.fileItem = item;
    },
  },
  setup() {
    const state = reactive({
      customStyle: { border: "gray 1px solid" },
      saveType: "image/png",
      saveOutput: "file",
    });
    const signatureDataURL = ref(null);
    const signatureFile = ref(null);
    const signaturePad = ref(null);

    const getSignaturePad = () => {
      if (!signaturePad.value) {
        throw new Error("No signature pad ref could be found");
      }
      return signaturePad.value;
    };

    const clearSignature = () => {
      getSignaturePad().clearSignature();
    };

    const saveSignature = () => {
      const signature = getSignaturePad().saveSignature();
      console.log(signature);
      return signature;
    };

    const onInput = (value) => {
      console.log("calling on input", value);
      if (!value) {
        signatureDataURL.value = null;
        signatureFile.value = null;
      } else if (value instanceof File) {
        signatureDataURL.value = null;
        signatureFile.value = value;
      } else {
        signatureDataURL.value = value;
        signatureFile.value = null;
      }
      console.log(signatureDataURL.value);
      console.log(signatureFile.value);
      console.log(signaturePad.value);
    };
    return {
      // state
      ...toRefs(state),
      signaturePad,
      signatureDataURL,
      signatureFile,
      // methods
      clearSignature,
      saveSignature,
      onInput,
    };
  },
});
